<template>
  <div class="statistics">
    <div class="controls">
      <button @click="previous">Previous</button>
      <button @click="next">Next</button>
    </div>
    <p>
      League:
      <select v-model="selectedLeague" @change="update()">
        <option value="All" selected>All</option>
        <option
          v-for="(league, index) in leagues"
          :key="index"
          :value="league._id"
          >{{ league.Name }}</option
        >
      </select>
      Season:
      <select v-model="selectedSeasons" @change="update()">
        <option value="All" selected>All</option>
        <option
          v-for="(season, index) in seasons"
          :key="index"
          :value="season._id"
          >{{ season.Name }}</option
        >
      </select>
      Team:
      <select v-model="selectedTeams" @change="update()">
        <option value="All" selected>All</option>
        <option v-for="(team, index) in teams" :key="index" :value="team._id">{{
          team.Name
        }}</option>
      </select>
    </p>
    <table>
      <caption>
        Statistics
      </caption>
      <thead>
        <tr>
          <th>Lastname</th>
          <th>Firstname</th>
          <th
            class="stats"
            @click="sort('Goals')"
            :class="{ sorted: sortBy == 'Goals' }"
          >
            G
          </th>
          <th
            class="stats"
            @click="sort('Assists')"
            :class="{ sorted: sortBy == 'Assists' }"
          >
            A
          </th>
          <th
            class="stats"
            @click="sort('MatchesPlayed')"
            :class="{ sorted: sortBy == 'MatchesPlayed' }"
          >
            MP
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in stats"
          :key="index"
          @click="goPlayer(row._id)"
        >
          <td>{{ row.Last_Name }}</td>
          <td>{{ row.First_Name }}</td>
          <td class="stats">{{ row.Goals }}</td>
          <td class="stats">{{ row.Assists }}</td>
          <td class="stats">{{ row.MatchesPlayed }}</td>
        </tr>
      </tbody>
    </table>
    <div class="controls">
      <button @click="previous">Previous</button>
      <button @click="next">Next</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      stats: [],
      sortBy: "Goals",
      sortAsc: false,
      page: 0,
      leagues: [],
      seasons: [],
      teams: [],
      selectedLeague: "All",
      selectedSeasons: "All",
      selectedTeams: "All",
    };
  },
  mounted() {
    axios.get(this.$apiUrl + "/seasons").then((res) => {
      this.seasons = res.data;
    });
    axios.get(this.$apiUrl + "/teams").then((res) => {
      this.teams = res.data;
    });
    axios.get(this.$apiUrl + "/menu").then((res) => {
      for (let league of res.data) {
        for (let child of league.childLeagues) {
          for (let div of child.Divisions) {
            this.leagues.push({
              Name: `${league.Name} > ${child.Name} > ${div.Name}`,
              _id: div._id,
            });
          }
        }
      }
    });
    this.update();
  },
  methods: {
    sort(key) {
      if (key == this.sortBy) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortBy = key;
        this.sortAsc = true;
      }
      this.update();
    },
    next() {
      this.page++;
      this.update();
    },
    previous() {
      if (this.page > 0) {
        this.page--;
      }

      this.update();
    },
    update() {
      this.stats = [];
      axios
        .get(this.$apiUrl + "/getPlayerStatistics", {
          params: {
            leagueID: this.selectedLeague,
            seasonID: this.selectedSeasons,
            teamID: this.selectedTeams,
            skipNumber: this.page * 30,
            limitNumber: 30,
            field: (this.sortAsc ? "" : "-") + this.sortBy,
          },
        })
        .then((res) => {
          this.stats = res.data;
        });
    },
    goPlayer(id) {
      this.$router.push({ path: `/players/${id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/main.scss";
.stats {
  min-width: 30px;
  text-align: center;
}
.controls {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  button {
    background-color: $secondary;
    color: white;
    padding: 10px;
    border: none;
  }
}
p {
  margin-bottom: 15px;
}
</style>
